import React from "react";

import { ANIM_DELAY } from "../../constants/constants";

import overlay from "../../images/overlay.svg";
import { Link } from "gatsby";

const Banner = () => {
 
 

  return (
    <div className="banner" id="banner">
      <div className="banner__overlay position-absolute">
        <img src={overlay} alt="overlay" className="w-100 h-100 " />
      </div>
      <div className="container">
        <div
          className="banner__content"
          data-sal="zoom-out"
          data-sal-delay={ANIM_DELAY}
          data-sal-easing="ease"
        >
          <h1 className="banner__title mb-5">
            AI+<span>SME</span> in-the-loop =
            <span className="banner__title_highlight">
              Applied Intelligence
            </span>
          </h1>
          <p className="banner__desc mb-5 text-white">
            Solving AI challenges precisely with Subject Matter Experts from our
            trusted network.
          </p>
          <div className="d-flex flex-row">
            <Link  to='/join-as-sme' className="btn btn-primary me-3">
              Join as SME
            </Link>
            <Link  to='/find-as-sme' className="btn btn-primary">
              Find an SME
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
